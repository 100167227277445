import { Injectable } from '@angular/core';
import * as Models from '../../models/models-index';
import * as chroma from 'chroma-js';
import { heatmapScenarios, totalRowScenarios } from '../../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class HeatmapService {
  getHeatmapColors(values: number[], isInverted: boolean = false): string[] {
    const totalValues = values.length;

    // Generate a color scale dynamically based on the number of cells in the column
    const colorScale = chroma.scale([heatmapScenarios.poorPerformanceColor, heatmapScenarios.averagePerformanceColor, heatmapScenarios.highPerformanceColor])
      .mode('lab')  // Use LAB color space for smoother transitions
      .colors(totalValues);  // Generate the exact number of colors required

    // Rank the values from smallest to largest
    const sortedValues = values
      .map((value, index) => ({ value, index }))  // Keep track of original indexes
      .sort((a, b) => a.value - b.value);  // Sort by value

    // Map of original index to the sorted rank for color assignment
    const colorMap: string[] = new Array(values.length);
    sortedValues.forEach(({ value, index }, rank) => {
      // If inverted, reverse the color scale assignment
      const adjustedRank = isInverted ? totalValues - 1 - rank : rank;
      colorMap[index] = colorScale[adjustedRank];
    });

    return colorMap;
  }

  applyHeatmapToRecords(records: any[], dataSet: Models.DataSet, columns: Models.IDefaultTableColumn[], columnNames: string[], totalRowPosition: string): any[] {
    const validRowIndices = this.getValidRowIndices(dataSet, totalRowPosition);

    columns.forEach(column => {
      const values: number[] = validRowIndices
        .map(rowIndex => dataSet.rows[rowIndex][columnNames.indexOf(column.columnDef)]?.value)
        .filter(value => typeof value === 'number') as number[];

      if (values.length === 0) return;

      const colors = this.getHeatmapColors(values, column.valueInverted);

      validRowIndices.forEach((rowIndex, valueIndex) => {
        const rawValue = dataSet.rows[rowIndex][columnNames.indexOf(column.columnDef)]?.value;
        if (typeof rawValue === 'number') {
          records[rowIndex][`${column.columnDef}${heatmapScenarios.heatmapColorSuffix}`] = colors[valueIndex];
        }
      });
    });

    return records;
  }

  applyGreyBackgroundToTotalRow(records: any[], totalRowPosition: string): void {
    let totalRow;
    if (totalRowPosition === totalRowScenarios.first) {
      totalRow = records[0];
    } else if (totalRowPosition === totalRowScenarios.last) {
      totalRow = records[records.length - 1];
    }

    if (totalRow) {
      Object.keys(totalRow).forEach(key => {
        totalRow[`${key}${heatmapScenarios.heatmapColorSuffix}`] = totalRowScenarios.color;
      });
    }
  }

  getBackgroundColor(row: any, column: any, heatmapEnabled: boolean): string {
    const heatmapColor = row[`${column.columnDef}${heatmapScenarios.heatmapColorSuffix}`];
    
    // Return the total row color if the current row is a total row.
    if (heatmapColor === totalRowScenarios.color) {
      return totalRowScenarios.color;
    }
    
    // Return the heatmap color if heatmap is enabled, otherwise return an empty string.
    return heatmapEnabled ? heatmapColor : '';
  }

  private getValidRowIndices(dataSet: Models.DataSet, totalRowPosition: string): number[] {
    let validRowIndices = Array.from(dataSet.rows.keys());
    if (totalRowPosition === totalRowScenarios.first) {
      validRowIndices = validRowIndices.slice(1);
    } else if (totalRowPosition === totalRowScenarios.last) {
      validRowIndices = validRowIndices.slice(0, -1);
    }
    return validRowIndices;
  }
}
